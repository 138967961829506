import classNames from 'classnames';
import { PropsWithChildren, useCallback, useState } from 'react';

import {
  EInsuranceFormInputTypes,
  EInsuranceType,
  ERetiredOrFamilyInsuredOptions,
  TInsuranceCompany,
  TInsuranceCompanyGroup,
  TInsuranceFormData,
  TInsuranceFormSharedProps,
} from '../../type';

import Input, { InputType } from 'components/Input/Input';
import Search from 'components/Search';
import Select from 'components/Select/Select';
import Spinner from 'components/Spinner';

import { trackEvent } from 'utils/analyticsUtils';

import { useInsuranceSelectionTranslations } from '../../InsuranceSelection.translations';
import { useInsuranceFormTranslations } from './InsuranceForm.translations';

import './styles.scss';

import {
  FALLBACK_GROUP_ID,
  INSURANCE_IDENTIFICATION_NUMBER,
  INSURANCE_NUMBER_PATTERN,
  getFallbackGroup,
} from '../../constants';
import InsuranceSuggestion, { InsuranceCompanySuggestion } from '../InsuranceSuggestion';
import NoInsuranceSearchResult from '../NoInsuranceSearchResult';

export type TInsuranceFormProps = PropsWithChildren<
  TInsuranceFormSharedProps & {
    insuranceFormData: TInsuranceFormData;
    updateInsuranceFormData: (insuranceFormData: Partial<TInsuranceFormData>) => void;
  }
>;

const InsuranceForm = ({
  isLoadingGroups,
  isLoadingAvailableCompanies,
  insuranceFormData,
  updateInsuranceFormData,
  selectedGroup,
  selectedCompany,
  queryGroup,
  setQueryGroup,
  queryIknr,
  setQueryIknr,
  onSearchInputCompany,
  showSuggestionsCompanies,
  setShowSuggestionsCompanies,
  showSuggestionsGroups,
  setShowSuggestionsGroups,
  onSelectCompany,
  onSearchInputGroup,
  onSelectGroup,
  suggestionsCompanies,
  suggestionsGroups,
  availableCompanies,
  forceCompanySelection,
}: TInsuranceFormProps) => {
  const translationsSelection = useInsuranceSelectionTranslations();
  const translationsForm = useInsuranceFormTranslations();
  const [isInsurantNumberDirty, setIsInsurantNumberDirty] = useState(true);

  const retiredOrFamilyInsuredOptions = [
    { value: ERetiredOrFamilyInsuredOptions.NOT_RETIRED, text: translationsForm.retiredOrFamilyInsuredOptionNo },
    {
      value: ERetiredOrFamilyInsuredOptions.FAMILY_INSURED,
      text: translationsForm.retiredOrFamilyInsuredOptionFamilyInsured,
    },
    { value: ERetiredOrFamilyInsuredOptions.PENSIONER, text: translationsForm.retiredOrFamilyInsuredOptionRetired },
  ];

  const handleInsuranceInputChange = (type: EInsuranceFormInputTypes, value: string) => {
    updateInsuranceFormData({ [type]: value });
  };

  const trackInputChange = (type: EInsuranceFormInputTypes, value: string) => {
    // For legal reasons we cannot track the actual input
    trackEvent({
      category: 'Input',
      action: `webapp:insuranceForm_${type}`,
      name: value ? 'value-entered' : 'value-cleared',
    });
    updateInsuranceFormData({ [type]: value });
  };

  const NoResultComponent = useCallback(() => {
    const handleSelectInsuranceType = (type: EInsuranceType) => {
      const fallbackCompany = getFallbackGroup(type, queryGroup);
      onSelectGroup(fallbackCompany);
      setShowSuggestionsGroups(false);
    };
    return <NoInsuranceSearchResult onInsuranceTypeSelected={handleSelectInsuranceType} />;
  }, [onSelectGroup, queryGroup, setShowSuggestionsGroups]);

  const showLoadingSpinnerCompanies = isLoadingAvailableCompanies;
  const showCompanySearch = availableCompanies.length > 1 || selectedGroup?.id === FALLBACK_GROUP_ID;
  const showInsurantNumberInput = !showLoadingSpinnerCompanies && selectedGroup?.type === 'GKV';
  const showFamilyInput = !showLoadingSpinnerCompanies && selectedGroup?.type === 'GKV';
  const isValidCompany = forceCompanySelection
    ? !!selectedCompany
    : !!selectedCompany || INSURANCE_IDENTIFICATION_NUMBER.test(queryIknr);

  return (
    <div className='insurance-form' data-testid='insurance-form'>
      <div className='form-line'>
        <Search<TInsuranceCompanyGroup>
          query={queryGroup}
          setQuery={setQueryGroup}
          showSuggestionList={showSuggestionsGroups}
          setShowSuggestionList={setShowSuggestionsGroups}
          placeholder={translationsSelection.placeholder}
          suggestions={suggestionsGroups}
          onSearchInput={onSearchInputGroup}
          onSelectSuggestion={onSelectGroup}
          maxLength={100}
          debounceTime={200}
          SuggestionComponent={InsuranceSuggestion}
          NoResultComponent={NoResultComponent}
          isFetchingSuggestions={isLoadingGroups}
          testId='group-search-input'
          autoFocus
          shouldCloseSuggestionsOnBlur
        />
      </div>

      {showLoadingSpinnerCompanies && <Spinner />}

      {showCompanySearch && (
        <div className={classNames('form-line', 'iknr-search-container')}>
          <Search<TInsuranceCompany>
            showIcon={false}
            query={queryIknr}
            setQuery={setQueryIknr}
            showSuggestionList={showSuggestionsCompanies}
            setShowSuggestionList={setShowSuggestionsCompanies}
            placeholder={translationsForm.insuranceIdentificationNumberLabel}
            suggestions={suggestionsCompanies.map((company) => ({
              ...company,
              // default key name is not unique
              key: company.iknr,
            }))}
            onSearchInput={onSearchInputCompany}
            onSelectSuggestion={onSelectCompany}
            maxLength={9}
            debounceTime={200}
            SuggestionComponent={InsuranceCompanySuggestion}
            isFetchingSuggestions={isLoadingGroups}
            testId='iknr-search-input'
            errorMessage={!isValidCompany ? translationsForm.insuranceIdentificationNumberError : undefined}
            shouldCloseSuggestionsOnBlur
          />
        </div>
      )}

      {showInsurantNumberInput && (
        <div className='form-line'>
          <Input
            testId='insurant-number-input'
            required
            value={insuranceFormData.insurantNumber}
            onChange={(event) =>
              handleInsuranceInputChange(EInsuranceFormInputTypes.INSURANT_NUMBER, event.target.value)
            }
            onBlur={() => trackInputChange(EInsuranceFormInputTypes.INSURANT_NUMBER, insuranceFormData.insurantNumber)}
            onFocus={() => setIsInsurantNumberDirty(true)}
            type={InputType.TEXT}
            errormessage={isInsurantNumberDirty ? translationsForm.insurantNumberError : undefined}
            label={translationsForm.insurantNumberLabel}
            maxLength={10}
            pattern={INSURANCE_NUMBER_PATTERN}
            autoFocus={false}
          />
        </div>
      )}

      {showFamilyInput && (
        <div className='form-line family-or-retired-container'>
          <div>{translationsForm.retiredOrFamilyInsuredLabel}</div>
          <Select
            testId='retired-or-family-insured-select'
            onSelect={(option: { text: string; value: string }) => {
              handleInsuranceInputChange(EInsuranceFormInputTypes.RETIRED_OR_FAMILY_INSURED, option.value);
              trackInputChange(EInsuranceFormInputTypes.RETIRED_OR_FAMILY_INSURED, option.value);
            }}
            selected={insuranceFormData.retiredOrFamilyInsured}
            options={retiredOrFamilyInsuredOptions}
          />
        </div>
      )}
    </div>
  );
};

export default InsuranceForm;
