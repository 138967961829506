import {
  EInsuranceSelectionError,
  TAddressFormData,
  TInsuranceCompanyGroup,
  TInsuranceFormData,
  TInsuranceFormSharedProps,
} from './type';

import BackButton from 'components/Button/BackButton';
import ContinueButton from 'components/Button/ContinueButton';
import Header from 'components/Header';
import SomethingWentWrong from 'components/SomethingWentWrong';
import Spinner from 'components/Spinner';

import AddressForm from './components/AddressForm';
import InsuranceForm from './components/InsuranceForm';

import { useInsuranceSelectionTranslations } from './InsuranceSelection.translations';

import './styles.scss';

export type TInsuranceSelectionUI = TInsuranceFormSharedProps & {
  isLoadingAvailableCompanies: boolean;
  isContinueDisabled: boolean;
  onGoBack: () => void;
  onContinue: () => void;
  errors: {
    [key in EInsuranceSelectionError]: Error | null;
  };
  allInsuranceGroups: TInsuranceCompanyGroup[] | null;
  insuranceFormData: TInsuranceFormData;
  updateInsuranceFormData: (formData: Partial<TInsuranceFormData>) => void;
  addressFormData: TAddressFormData;
  updateAddressFormData: (formData: Partial<TAddressFormData>) => void;
  askForAddress: boolean;
};

const InsuranceSelectionUi = ({
  isLoadingGroups,
  isLoadingAvailableCompanies,
  isContinueDisabled,
  insuranceFormData,
  updateInsuranceFormData,
  addressFormData,
  updateAddressFormData,
  onGoBack,
  onContinue,
  errors,
  suggestionsGroups,
  suggestionsCompanies,
  availableCompanies,
  selectedGroup,
  selectedCompany,
  onSelectGroup,
  onSelectCompany,
  onSearchInputGroup,
  onSearchInputCompany,
  queryGroup,
  setQueryGroup,
  queryIknr,
  setQueryIknr,
  showSuggestionsGroups,
  setShowSuggestionsGroups,
  showSuggestionsCompanies,
  setShowSuggestionsCompanies,
  askForAddress,
  allInsuranceGroups,
  forceCompanySelection,
}: TInsuranceSelectionUI) => {
  const translations = useInsuranceSelectionTranslations();

  const firstError = Object.keys(errors).find((key) => !!errors[key as EInsuranceSelectionError]) as
    | EInsuranceSelectionError
    | undefined;

  const insuranceFormProps: TInsuranceFormSharedProps = {
    isLoadingGroups,
    isLoadingAvailableCompanies,
    queryGroup,
    setQueryGroup,
    queryIknr,
    setQueryIknr,
    suggestionsGroups,
    suggestionsCompanies,
    availableCompanies,
    selectedGroup,
    selectedCompany,
    onSearchInputGroup,
    onSearchInputCompany,
    onSelectGroup,
    onSelectCompany,
    showSuggestionsGroups,
    setShowSuggestionsGroups,
    showSuggestionsCompanies,
    setShowSuggestionsCompanies,
    forceCompanySelection,
  };

  const showLoadingSpinnerGroups = !firstError && isLoadingGroups;
  const showForm = !firstError && !isLoadingGroups && allInsuranceGroups;

  return (
    <section className='insurance-selection'>
      <div className='page-content'>
        <Header title={translations.title} subtitle={translations.description} />

        {firstError && (
          <SomethingWentWrong
            description={translations[firstError]}
            error={errors[firstError]}
            parentScreen='InsuranceSelection'
          />
        )}

        {showLoadingSpinnerGroups && <Spinner />}

        {showForm && (
          <form
            name='insurance-form'
            className='insurance-form-container'
            autoComplete='on'
            onSubmit={(e) => e.preventDefault()}>
            <InsuranceForm
              {...insuranceFormProps}
              insuranceFormData={insuranceFormData}
              updateInsuranceFormData={updateInsuranceFormData}
            />

            {askForAddress && selectedGroup && (
              <AddressForm addressFormData={addressFormData} updateAddressFormData={updateAddressFormData} />
            )}
          </form>
        )}
      </div>
      <div className='fixed-buttons buttons'>
        <BackButton onClick={onGoBack} />
        <ContinueButton testid='continue-button' onClick={onContinue} disabled={isContinueDisabled} />
      </div>
    </section>
  );
};

export default InsuranceSelectionUi;
