export type TInsuranceCompanyGroup = {
  id: string;
  name: string;
  type: 'GKV' | 'PKV';
  highlight: boolean;
  iknr: string;
};

export type TInsuranceCompany = {
  name: string;
  iknr: string;
};

export enum EInsuranceType {
  GKV = 'GKV',
  PKV = 'PKV',
}

export enum ERetiredOrFamilyInsuredOptions {
  NOT_RETIRED = 'NOT_RETIRED',
  FAMILY_INSURED = 'FAMILY_INSURED',
  PENSIONER = 'PENSIONER',
}

export enum EInsuranceFormInputTypes {
  INSURANT_NUMBER = 'insurantNumber',
  RETIRED_OR_FAMILY_INSURED = 'retiredOrFamilyInsured',
}

export type TInsuranceFormData = {
  [EInsuranceFormInputTypes.INSURANT_NUMBER]: string;
  [EInsuranceFormInputTypes.RETIRED_OR_FAMILY_INSURED]: ERetiredOrFamilyInsuredOptions;
};

export enum EAddressFormInputTypes {
  STREET = 'street',
  HOUSE_NUMBER = 'houseNumber',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  COUNTRY = 'country',
  CARE_OF = 'careOf',
}

export type TAddressFormData = {
  [EAddressFormInputTypes.STREET]: string;
  [EAddressFormInputTypes.HOUSE_NUMBER]: string;
  [EAddressFormInputTypes.ZIP_CODE]: string;
  [EAddressFormInputTypes.CITY]: string;
  [EAddressFormInputTypes.COUNTRY]: string;
  [EAddressFormInputTypes.CARE_OF]: string;
};

export enum EInsuranceSelectionError {
  GROUP_ERROR = 'INSURANCE_ERROR',
  COMPANY_ERROR = 'COMPANY_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export type TInsuranceFormSharedProps = {
  isLoadingGroups: boolean;
  isLoadingAvailableCompanies: boolean;
  queryGroup: string;
  setQueryGroup: (query: string) => void;
  queryIknr: string;
  setQueryIknr: (query: string) => void;
  suggestionsGroups: TInsuranceCompanyGroup[];
  suggestionsCompanies: TInsuranceCompany[];
  availableCompanies: TInsuranceCompany[];
  selectedGroup: TInsuranceCompanyGroup | null;
  selectedCompany: TInsuranceCompany | null;
  onSearchInputGroup: (query: string) => void;
  onSearchInputCompany: (query: string) => void;
  onSelectGroup: (insurance: TInsuranceCompanyGroup | null) => void;
  onSelectCompany: (insurance: TInsuranceCompany | null) => void;
  showSuggestionsGroups: boolean;
  setShowSuggestionsGroups: (show: boolean) => void;
  showSuggestionsCompanies: boolean;
  setShowSuggestionsCompanies: (show: boolean) => void;
  forceCompanySelection: boolean;
};
