import LoadingImg from 'assets/images/spinner.svg';
import { FC } from 'react';

import './styles.scss';

const Spinner: FC = () => {
  return (
    <div className='spinner-container'>
      <img data-testid='loading-icon' src={LoadingImg} alt='loading icon' width='24' height='24' />
    </div>
  );
};

export default Spinner;
