import { FALLBACK_GROUP_ID } from 'pages/Booking/InsuranceSelection/constants';
import { webApi } from 'services/api';

import { TInsuranceCompany, TInsuranceCompanyGroup } from 'pages/Booking/InsuranceSelection/type';

export const fetchInsuranceGroupsApi = async (): Promise<TInsuranceCompanyGroup[]> => {
  const url = '/api/v1/insurance/group';

  const response = await webApi.get(url);
  return response.data.items;
};

export const fetchInsuranceCompaniesByGroupApi = async (id: string): Promise<TInsuranceCompany[]> => {
  if (id === FALLBACK_GROUP_ID) {
    return [];
  }

  const url = `/api/v1/insurance/group/${id}/company`;

  const response = await webApi.get(url);
  return response.data.items;
};
