import { isEmpty } from 'lodash';
import { TPatientAddressDto, TPatientGroupWithCompany, TPatientInsuranceDto } from 'services/patient';

import {
  EAddressFormInputTypes,
  ERetiredOrFamilyInsuredOptions,
  TAddressFormData,
  TInsuranceCompany,
  TInsuranceCompanyGroup,
  TInsuranceFormData,
} from './type';

import { INSURANCE_IDENTIFICATION_NUMBER, INSURANCE_NUMBER_PATTERN } from './constants';

export const normalizeInsuranceSearchQuery = (value: string): string => value.toLowerCase().replace(/\s/g, '');

export const mapGroupWithCompanyFromFormData = (
  selectedGroup: TInsuranceCompanyGroup,
  selectedCompany: TInsuranceCompany | null,
  // used when a company iknr is submitted that is not in the list and thus not selectable
  selectedCompanyIknr: string | null,
  insuranceFormData: TInsuranceFormData
): TPatientGroupWithCompany => ({
  type: selectedGroup?.type || '',
  titleGroup: selectedGroup?.name || '',
  iknrGroup: selectedGroup?.iknr || '',
  titleCompany: selectedCompany?.name || '',
  iknrCompany: selectedCompany?.iknr || selectedCompanyIknr || '',
  number: insuranceFormData?.insurantNumber || '',
  status: insuranceFormData?.retiredOrFamilyInsured || ERetiredOrFamilyInsuredOptions.NOT_RETIRED,
});

export const mapInsuranceDtoFromGroupWithCompany = (
  groupWithCompany: TPatientGroupWithCompany
): TPatientInsuranceDto => ({
  type: groupWithCompany.type,
  // we always use the company name and iknr if available
  title: groupWithCompany.titleCompany || groupWithCompany.titleGroup || '',
  iknr: groupWithCompany.iknrCompany || groupWithCompany.iknrGroup || '',
  number: groupWithCompany.number,
  status: groupWithCompany.status,
});

export const isValidInsuranceGroupWithCompany = (groupWithCompany: TPatientGroupWithCompany): boolean => {
  // we always need a company iknr
  if (!groupWithCompany.iknrCompany) return false;

  const resultingDto = mapInsuranceDtoFromGroupWithCompany(groupWithCompany);
  return isValidInsuranceDto(resultingDto);
};

export const isValidInsuranceDto = (dto: TPatientInsuranceDto): boolean => {
  const isValidType = ['GKV', 'PKV'].includes(dto.type);
  const isValidName = dto.title.trim() !== '';

  let isValidIknr = INSURANCE_IDENTIFICATION_NUMBER.test(dto.iknr);

  let isValidNumber = INSURANCE_NUMBER_PATTERN.test(dto.number);
  if (dto.type === 'PKV') {
    // we don't ask for insurant number in case of PKV
    isValidNumber = true;
  }
  const isValidStatus = Object.values(ERetiredOrFamilyInsuredOptions).includes(dto.status);

  return isValidType && isValidName && isValidIknr && isValidNumber && isValidStatus;
};

export const mapAddressFromFormData = (addressFormData: TAddressFormData): TPatientAddressDto => ({
  // We are storing the street number field with the street field in the backend
  street: `${addressFormData.street} ${addressFormData.houseNumber ?? ''}`,
  careOf: addressFormData.careOf,
  postCode: addressFormData.zipCode,
  city: addressFormData.city,
  country: addressFormData.country,
});

export const isValidAddressFormData = (address: TAddressFormData): boolean => {
  return Object.entries(address)
    .filter(([key, _]) => key !== EAddressFormInputTypes.CARE_OF)
    .every(([_, value]) => !isEmpty(value) && !(value.trim().length === 0)); // check for empty or containing only whitespace strings
};
