import Title from 'components/Title';

import './styles.scss';

type TProps = {
  title: string | JSX.Element | (string | JSX.Element)[];
  subtitle?: string | JSX.Element | (string | JSX.Element)[];
  titleTestId?: Lowercase<string>;
  subtitleTestId?: Lowercase<string>;
  size?: 'xl' | 'lg' | 'md' | 'sm';
  marginTop?: string;
};

const Header = ({
  title,
  subtitle,
  titleTestId = 'header-title',
  subtitleTestId = 'header-subtitle',
  size = 'xl',
  marginTop,
}: TProps) => {
  return (
    <div className='header' style={marginTop ? { marginTop } : {}}>
      <Title testId={titleTestId} title={title} size={size} />
      {subtitle && (
        <p className='subtitle font-md' data-testid={subtitleTestId}>
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default Header;
