// Components
import classNames from 'classnames';
import React, { CSSProperties, ChangeEvent, Component } from 'react';
import AnimateHeight from 'react-animate-height';

import { emailValidator } from 'utils/emailValidator';

import './_styles.scss';

import CountDownTimer, { TCountDownTimerProps } from '../CountDownTimer/CountDownTimer';

export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
}
export enum InputStatus {
  INITIAL = '',
  ACTIVE = 'active',
  HAS_VALUE = 'has-value',
  EMPTY = 'invalid', // TODO: invalid should be different than empty, refactor together with the input
  INVALID = 'has-value invalid',
}
export type TInputProps = {
  type: InputType;
  label?: string;
  id?: string;
  value?: string;
  defaultValue?: string;
  placeHolder?: string;
  max?: number;
  min?: number;
  minLength?: number;
  maxLength?: number;
  tabIndex?: number;
  onChange?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onBlur?: () => void;
  onFocus?: (e: any) => void;
  onTogglePassword?: (visible: boolean) => void;
  required?: boolean;
  autoFocus?: boolean;
  autoJump?: boolean;
  errormessage?: string;
  style?: CSSProperties;
  showPassword?: boolean;
  showClearButton?: boolean;
  showAsInvalid?: boolean;
  disabled?: boolean;
  showTimer?: boolean;
  timerProps?: TCountDownTimerProps;
  autoComplete?: string;
  pattern?: RegExp;
  textCenter?: boolean;
  testId?: Lowercase<string>;
};

export default class Input extends Component<TInputProps, any> {
  inputElement: any;
  showClearButton: boolean;

  constructor(props: TInputProps) {
    super(props);

    this.inputElement = React.createRef();
    this.state = {
      status: props.value || props.defaultValue ? InputStatus.HAS_VALUE : InputStatus.INITIAL,
    };
    this.showClearButton = props.showClearButton === undefined ? true : props.showClearButton;

    this.focus = this.focus.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  jumpToNext(e: ChangeEvent<HTMLInputElement>) {
    const currentIndex = Number(e.target.getAttribute('tabindex'));
    const elements = Array.from(document.querySelectorAll('[tabindex]'));
    const nextIndex = elements.map((el) => Number(el.getAttribute('tabindex'))).find((i) => i > currentIndex);
    const nextElement = elements.find(
      (el) => el.getAttribute('tabindex') === nextIndex?.toString()
    ) as HTMLInputElement;

    if (nextElement) nextElement.focus();
  }

  onFocus() {
    this.props.onFocus?.(null);
    this.setState({ ...this.state, status: InputStatus.ACTIVE });
  }

  onBlur() {
    let newStatus = InputStatus.INITIAL;

    if (this.props.required && this.inputElement.current.value === '') newStatus = InputStatus.EMPTY;
    if (this.inputElement.current && this.inputElement.current.value !== '') newStatus = InputStatus.HAS_VALUE;
    if (
      (newStatus !== InputStatus.EMPTY &&
        this.props.type === InputType.EMAIL &&
        !emailValidator(this.inputElement.current.value)) ||
      (this.props.type === InputType.NUMBER &&
        (this.inputElement.current.value === '' ||
          Number(this.inputElement.current.value) < Number(this.props.min) ||
          Number(this.inputElement.current.value) > Number(this.props.max)))
    ) {
      newStatus = InputStatus.INVALID;
    }
    if (this.props.pattern && !this.props.pattern.test(this.inputElement.current.value))
      newStatus = InputStatus.INVALID;

    this.setState({ ...this.state, status: newStatus });
    this.props?.onBlur?.();
  }

  onChange(e: any) {
    this.setState({
      ...this.state,
      status: e.target.value !== '' ? InputStatus.HAS_VALUE : InputStatus.INITIAL,
    });

    if (this.props.onChange) this.props.onChange(e);
    if (this.props.autoJump && e.target.value.length >= (this.props.maxLength || 1)) this.jumpToNext(e);
  }

  onKeyUp(e: any) {
    if (this.props.onKeyUp) this.props.onKeyUp(e);
  }

  focus() {
    this.inputElement.current.focus();
  }

  render() {
    const {
      timerProps,
      showTimer,
      autoJump,
      showAsInvalid,
      showClearButton,
      pattern,
      textCenter,
      testId = 'input',
      ...rest
    } = this.props;
    return (
      <div
        data-testid={`${testId}-container` as Lowercase<string>}
        className={`pwa-textbox ${this.props.showAsInvalid ? 'invalid' : this.state.status} ${
          this.props.showAsInvalid ? 'error' : this.state.status // used to scroll to error on User data page. Invalid should be refactored to error
        }`}>
        {this.props.label && <label className={`label ${this.state.status}`}>{this.props.label}</label>}
        <div className='input-container'>
          <input
            data-testid={testId}
            className={classNames(
              this.props.label ? this.state.status : `${this.state.status} no-label`,
              textCenter ? 'text-center' : null
            )}
            ref={this.inputElement}
            {...rest}
            placeholder={this.props.label}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onKeyUp={this.onKeyUp}
          />
          {this.showClearButton && (
            <button
              type='button'
              className={'input-action clear'}
              onClick={(e) => {
                this.inputElement.current.value = '';
                this.setState({ ...this.state, status: InputStatus.INITIAL });
                if (this.props.onChange) this.props.onChange({ target: { value: '' } });
              }}
              onMouseDown={(e) => e.preventDefault()}
              style={{
                visibility: this.state.status.includes(InputStatus.HAS_VALUE) ? 'visible' : 'hidden',
              }}
            />
          )}
          {this.props.type === InputType.PASSWORD && (
            <button
              type='button'
              className={'input-action ' + (this.props.showPassword ? 'show-pwd' : 'hide-pwd')}
              onClick={() => {
                const box = this.inputElement.current;
                if (!box) return;

                if (box.getAttribute('type') === 'password') {
                  box.setAttribute('type', 'text');
                  if (this.props.onTogglePassword) this.props.onTogglePassword(true);
                } else {
                  box.setAttribute('type', 'password');
                  if (this.props.onTogglePassword) this.props.onTogglePassword(false);
                }
              }}
            />
          )}
        </div>
        {this.props.showTimer && this.props.timerProps && <CountDownTimer {...this.props.timerProps} />}

        <AnimateHeight
          duration={150}
          height={
            this.props.errormessage &&
            (this.state.status === InputStatus.INVALID || this.state.status === InputStatus.EMPTY)
              ? 'auto'
              : 0
          }>
          {this.state.status === InputStatus.INVALID || this.state.status === InputStatus.EMPTY ? (
            <span className='error'>{this.props.errormessage}</span>
          ) : null}
        </AnimateHeight>
      </div>
    );
  }
}
