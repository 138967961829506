import { TInsuranceCompany, TInsuranceCompanyGroup } from '../../type';

import './styles.scss';

const InsuranceSuggestion = ({ suggestion }: { suggestion: TInsuranceCompanyGroup }) => {
  return (
    <div className='suggestion-container'>
      <div className='suggestion-name'>{suggestion.name}</div>
      <div className='suggestion-type'>{suggestion.type}</div>
    </div>
  );
};

export const InsuranceCompanySuggestion = ({ suggestion }: { suggestion: TInsuranceCompany }) => {
  return (
    <div className='suggestion-container'>
      <div className='suggestion-name'>{suggestion.iknr}</div>
      <div className='suggestion-type'>{suggestion.name}</div>
    </div>
  );
};

export default InsuranceSuggestion;
