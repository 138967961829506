import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { ChangeEventHandler, ComponentType, useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import './styles.scss';

import SuggestionList from '../SuggestionList';

interface ISearchProps<T> {
  placeholder?: string;
  onSearchInput: (query: string) => void;
  maxLength?: number;
  suggestions: T[];
  onSelectSuggestion: (suggestion: T | null) => void;
  NoResultComponent?: ComponentType;
  debounceTime?: number;
  autoFocus?: boolean;
  SuggestionComponent?: ComponentType<{ suggestion: T }>;
  query: string;
  setQuery: (query: string) => void;
  showSuggestionList: boolean;
  setShowSuggestionList: (show: boolean) => void;
  showIcon?: boolean;
  shouldCloseSuggestionsOnBlur?: boolean;
  isFetchingSuggestions: boolean;
  testId?: Lowercase<string>;
  errorMessage?: string;
  onFocus?: () => void;
  onChange?: () => void;
  autoComplete?: string;
}

function Search<T extends { name: string }>({
  suggestions,
  onSelectSuggestion,
  NoResultComponent,
  placeholder,
  onSearchInput,
  showIcon = true,
  maxLength = 70,
  autoFocus = true,
  debounceTime = 200,
  SuggestionComponent,
  query,
  setQuery,
  showSuggestionList,
  setShowSuggestionList,
  shouldCloseSuggestionsOnBlur,
  isFetchingSuggestions,
  testId = 'search-input',
  errorMessage,
  onFocus,
  onChange,
  autoComplete,
}: Readonly<ISearchProps<T>>) {
  const containerTestId = `${testId}-container` as Lowercase<string>;
  const [isFocused, setIsFocused] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((searchQuery: string) => {
        onSearchInput(searchQuery);
      }, debounceTime),
    [onSearchInput, debounceTime]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    onSelectSuggestion(null);
    setQuery(value);

    if (debounceTime === 0) {
      // no debounce for google places autocomplete, as it has its own debounce
      onSearchInput(value);
    } else {
      debouncedSearch(value);
    }
    onChange?.();
  };

  const handleSelectSuggestion = (suggestion: T) => {
    onSelectSuggestion(suggestion);
    setShowSuggestionList(false);
  };

  const handleContainerFocus = () => {
    setIsFocused(true);
    setShowSuggestionList(true);
  };

  const handleContainerBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    setIsFocused(false);

    if (shouldCloseSuggestionsOnBlur && !event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => {
        setShowSuggestionList(false);
      }, 200);
    }
  };

  return (
    <div
      className={classNames('search-container')}
      data-testid={containerTestId}
      onFocus={handleContainerFocus}
      onBlur={handleContainerBlur}>
      <input
        data-testid={testId}
        className={classNames({ 'with-icon': showIcon, error: errorMessage })}
        type='text'
        onChange={handleInputChange}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        value={query}
        onFocus={onFocus}
        autoComplete={autoComplete}
      />
      <div className={classNames('search-list-container', { show: showSuggestionList })}>
        {(suggestions.length > 0 || !!NoResultComponent) && (
          <SuggestionList<T>
            suggestions={suggestions}
            query={query}
            isLoading={isFetchingSuggestions}
            onSelectSuggestion={handleSelectSuggestion}
            Suggestion={SuggestionComponent}
            NoResultComponent={NoResultComponent}
          />
        )}
      </div>
      <AnimateHeight duration={150} height={!isFocused && errorMessage ? 'auto' : 0}>
        {errorMessage && <span className='errorMessage'>{errorMessage}</span>}
      </AnimateHeight>
    </div>
  );
}

export default Search;
