import { ERetiredOrFamilyInsuredOptions, TAddressFormData, TInsuranceCompanyGroup, TInsuranceFormData } from './type';

export const INSURANCE_NUMBER_PATTERN = /^[A-Za-z]\d{9}$/;
export const INSURANCE_IDENTIFICATION_NUMBER = /^\d{9}$/;
export const AT_LEAST_ONE_NON_WHITESPACE_CHAR = /^(?!\s*$).+/;

export const defaultAddressFormData: TAddressFormData = {
  street: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  country: '',
  careOf: '',
};

export const defaultInsuranceFormData: TInsuranceFormData = {
  insurantNumber: '',
  retiredOrFamilyInsured: ERetiredOrFamilyInsuredOptions.NOT_RETIRED,
};

export const FALLBACK_GROUP_ID = 'unknown';
export const getFallbackGroup = (type: 'GKV' | 'PKV', name: string): TInsuranceCompanyGroup => ({
  id: FALLBACK_GROUP_ID,
  iknr: FALLBACK_GROUP_ID,
  type,
  name,
  highlight: false,
});
