import { useIntl } from 'react-intl';

export const useAutoCompleteStreetTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    placeholder: formatMessage({
      id: 'Components.AutoCompleteStreet.placeholder',
      description: 'Placeholder for the street input',
      defaultMessage: 'Straße',
    }),
    noResultTitle: formatMessage({
      id: 'Components.AutoCompleteStreet.noResultTitle',
      description: 'Title for no result',
      defaultMessage: 'Wir konnten keine Adresse für die automatische Vervollständigung finden.',
    }),
    noResultDescription: formatMessage({
      id: 'Components.AutoCompleteStreet.noResultDescription',
      description: 'Description for no result',
      defaultMessage: 'Bitte geben Sie Ihre Adressdaten manuell ein',
    }),
    invalidStreetError: formatMessage({
      id: 'Components.AutoCompleteStreet.invalidStreetError',
      description: 'Error message for invalid street input',
      defaultMessage: 'Der Straßenname darf nicht leer sein',
    }),
  };
};
