import useAppContext from 'AppContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetZipCodeValidation, validateZipCode } from 'redux/Slices/address';
import { fetchInsuranceCompaniesByGroupApi, fetchInsuranceGroupsApi } from 'redux/Slices/insurance/api/insuranceApi';
import { TRootState, useAppDispatch } from 'redux/Store/store';

import {
  EInsuranceSelectionError,
  TAddressFormData,
  TInsuranceCompany,
  TInsuranceCompanyGroup,
  TInsuranceFormData,
} from './type';

import BookingPageContainer from 'components/BookingPageContainer';

import InsuranceSelectionUi from './InsuranceSelection.ui';

import {
  isValidAddressFormData,
  isValidInsuranceDto,
  isValidInsuranceGroupWithCompany,
  mapAddressFromFormData,
  mapGroupWithCompanyFromFormData,
  mapInsuranceDtoFromGroupWithCompany,
  normalizeInsuranceSearchQuery,
} from './utils';
import { trackEvent, trackPageView, trackSiteSearch } from 'utils/analyticsUtils';
import { AppRoutes } from 'utils/routeUtils';

import useRemoteConfig from 'hooks/useRemoteConfig';

import { useInsuranceSelectionTranslations } from './InsuranceSelection.translations';

import { FALLBACK_GROUP_ID, defaultAddressFormData, defaultInsuranceFormData } from './constants';

const InsuranceSelectionData = () => {
  const [insuranceFormData, setInsuranceFormData] = useState<TInsuranceFormData>(defaultInsuranceFormData);
  const [addressFormData, setAddressFormData] = useState<TAddressFormData>(defaultAddressFormData);

  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  const [query, setQuery] = useState('');
  const [queryIknr, setQueryIknr] = useState('');
  const [showSuggestionsGroups, setShowSuggestionGroups] = useState(true);
  const [showSuggestionsCompanies, setShowSuggestionsCompanies] = useState(false);

  const {
    values: { force_insurance_company_selection = false },
  } = useRemoteConfig();
  const {
    data: allInsuranceGroups = null,
    isFetching: isFetchingGroups,
    error: errorFetchingGroups = null,
  } = useQuery('insuranceGroups', fetchInsuranceGroupsApi, {
    retry: process.env.NODE_ENV === 'test' ? false : undefined,
    onError: () => {
      trackEvent({ category: 'View', action: 'InsuranceSelection-FetchInsurance-Error' });
    },
  });
  const [selectedInsuranceGroup, setSelectedInsuranceGroup] = useState<TInsuranceCompanyGroup | null>(null);
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState<TInsuranceCompany | null>(null);

  const {
    data: availableInsuranceCompanies,
    isFetching: isFetchingAvailableCompanies,
    error: errorFetchingAvailableCompanies = null,
  } = useQuery(
    ['availableInsuranceCompanies', selectedInsuranceGroup?.id],
    () => fetchInsuranceCompaniesByGroupApi(selectedInsuranceGroup!.id),
    {
      enabled: !!selectedInsuranceGroup,
      retry: process.env.NODE_ENV === 'test' ? false : undefined,
      onSuccess: (resp) => {
        // we select the first and only company, or the group itself if no company is available
        if (resp.length === 1) {
          setSelectedInsuranceCompany(resp[0]);
        } else if (resp.length === 0) {
          if (selectedInsuranceGroup?.id === FALLBACK_GROUP_ID) return;
          const company = {
            name: selectedInsuranceGroup!.name,
            iknr: selectedInsuranceGroup!.iknr,
          };
          setSelectedInsuranceCompany(company);
        }
      },
    }
  );
  const {
    context: {
      apptData: { isVideoCall },
    },
  } = useAppContext();

  const { error: zipCodeValidationError } = useSelector((state: TRootState) => state.addressReducer.zipCodeValidation);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translations = useInsuranceSelectionTranslations();

  const isInsuranceFormValid = useMemo(() => {
    if (!selectedInsuranceGroup) return false;
    if (force_insurance_company_selection && !selectedInsuranceCompany) return false;

    const groupWithCompany = mapGroupWithCompanyFromFormData(
      selectedInsuranceGroup,
      selectedInsuranceCompany,
      queryIknr,
      insuranceFormData
    );

    return isValidInsuranceGroupWithCompany(groupWithCompany);
  }, [
    insuranceFormData,
    selectedInsuranceGroup,
    selectedInsuranceCompany,
    queryIknr,
    force_insurance_company_selection,
  ]);

  const isAddressFormValid = useMemo(() => {
    return isValidAddressFormData(addressFormData);
  }, [addressFormData]);

  useEffect(() => {
    trackPageView({ href: window.location.href });
    trackEvent({
      category: 'View',
      action: 'webapp:insuranceForm',
    });
    return () => {
      // Reset any validation errors which may have occurred when leaving
      dispatch(resetZipCodeValidation());
    };
  }, [dispatch]);

  useEffect(() => {
    setIsContinueDisabled(!isInsuranceFormValid || !isAddressFormValid);
  }, [isInsuranceFormValid, isAddressFormValid, selectedInsuranceGroup]);

  const suggestionsGroups = useMemo(() => {
    if (!allInsuranceGroups) return [];

    // list highlighted groups only if no query is set
    if (!query.length) {
      return allInsuranceGroups.filter((group) => group.highlight);
    }

    const formattedQuery = normalizeInsuranceSearchQuery(query);
    trackSiteSearch({ keyword: formattedQuery, category: 'webapp:insuranceSearch' });

    return allInsuranceGroups.filter((group) => normalizeInsuranceSearchQuery(group.name).includes(formattedQuery));
  }, [allInsuranceGroups, query]);

  const suggestionsCompanies = useMemo(() => {
    if (!availableInsuranceCompanies) return [];

    // list all availble iknrs if no query is set
    if (!queryIknr.length) {
      return availableInsuranceCompanies;
    }

    const formattedQuery = normalizeInsuranceSearchQuery(queryIknr);
    const hits = availableInsuranceCompanies.filter((company) =>
      normalizeInsuranceSearchQuery(company.iknr).includes(formattedQuery)
    );

    // if no hits, return all companies since we want the user to select one
    if (hits.length === 0) {
      return availableInsuranceCompanies;
    }

    return hits;
  }, [availableInsuranceCompanies, queryIknr]);

  const handleSelectInsurance = async (insurance: TInsuranceCompanyGroup | null) => {
    setSelectedInsuranceGroup(insurance);
    setSelectedInsuranceCompany(null);
    setQueryIknr('');

    if (insurance) {
      setQuery(insurance.name);
      // We would like to create funnels and segments based on the insurance type (PKV/GKV). Which insurance was selected is not that relevant.
      // Since matomo does not support proper segmenting of events, we therefore need to fire 2 separate events.
      trackEvent({
        category: 'Click',
        action: 'webapp:insuranceSelectType',
        name: insurance.type,
      });
      trackEvent({
        category: 'Click',
        action: 'webapp:insuranceSelected',
        name: insurance.name,
      });
    }
  };

  const handleSelectInsuranceCompany = async (insurance: TInsuranceCompany | null) => {
    setSelectedInsuranceCompany(insurance);
    setQueryIknr(insurance?.iknr ?? '');
  };

  const handleGoBack = () => {
    trackEvent({ category: 'Click', action: 'webapp:insuranceSelection_back' });
    navigate(-1);
  };
  const handleContinue = async () => {
    setIsContinueDisabled(true);

    trackEvent({ category: 'Click', action: 'webapp:insuranceSelection_continue' });

    const groupWithCompany = mapGroupWithCompanyFromFormData(
      selectedInsuranceGroup!,
      selectedInsuranceCompany,
      queryIknr,
      insuranceFormData
    );
    const draftInsuranceDto = mapInsuranceDtoFromGroupWithCompany(groupWithCompany);
    const insuranceDto = isValidInsuranceDto(draftInsuranceDto) ? draftInsuranceDto : null;
    console.log('groupWithCompany', groupWithCompany);
    console.log('draftInsuranceDto', draftInsuranceDto);
    console.log('actual insuranceDto', insuranceDto);

    const draftAddressDto = mapAddressFromFormData(addressFormData);
    const addressDto = isValidAddressFormData(addressFormData) ? draftAddressDto : null;
    console.log('draftAddressDto', draftAddressDto);
    console.log('actual addressDto', addressDto);

    const routeState = {
      insurance: insuranceDto,
      address: addressDto,
    };

    // only validate zip code if it is a video call
    if (isVideoCall) {
      const validationResult = await dispatch(validateZipCode(addressDto?.postCode ?? ''))
        .unwrap()
        .catch((e) => {
          trackEvent({
            category: 'View',
            action: 'webapp:insuranceSelection_zipCodeValidation_error',
            name: e.message,
          });
        });

      if (validationResult?.enabled) {
        trackEvent({ category: 'View', action: 'webapp:insuranceSelection_navigate_continue' });
        navigate(AppRoutes.Authentication(), { state: routeState });
      } else {
        trackEvent({
          category: 'View',
          action: 'webapp:insuranceSelection_navigate_decline',
          name: addressDto?.postCode ?? '',
        });
        navigate(AppRoutes.OutOfReach(), { state: routeState });
      }
    } else {
      trackEvent({ category: 'View', action: 'webapp:insuranceSelection_navigate_continue' });
      navigate(AppRoutes.Authentication(), { state: routeState });
    }

    setIsContinueDisabled(false);
  };

  const updateInsuranceFormData = useCallback(
    (newInsuranceFormValues: Partial<TInsuranceFormData>) => {
      setInsuranceFormData((old) => ({
        ...old,
        ...newInsuranceFormValues,
      }));
    },
    [setInsuranceFormData]
  );

  const updateAddressFormData = useCallback(
    (newAddressFormValues: Partial<TAddressFormData>) => {
      setAddressFormData((old) => ({
        ...old,
        ...newAddressFormValues,
      }));
    },
    [setAddressFormData]
  );

  const errors = {
    [EInsuranceSelectionError.GROUP_ERROR]: errorFetchingGroups as Error | null,
    [EInsuranceSelectionError.COMPANY_ERROR]: errorFetchingAvailableCompanies as Error | null,
    [EInsuranceSelectionError.VALIDATION_ERROR]: zipCodeValidationError as Error | null,
  };
  return (
    <BookingPageContainer documentTitle={translations.documentTitle}>
      <InsuranceSelectionUi
        isLoadingGroups={isFetchingGroups}
        isLoadingAvailableCompanies={isFetchingAvailableCompanies}
        onGoBack={handleGoBack}
        onContinue={handleContinue}
        isContinueDisabled={isContinueDisabled}
        queryGroup={query}
        setQueryGroup={setQuery}
        queryIknr={queryIknr}
        setQueryIknr={setQueryIknr}
        showSuggestionsGroups={showSuggestionsGroups}
        showSuggestionsCompanies={showSuggestionsCompanies}
        setShowSuggestionsGroups={setShowSuggestionGroups}
        setShowSuggestionsCompanies={setShowSuggestionsCompanies}
        errors={errors}
        suggestionsGroups={suggestionsGroups}
        suggestionsCompanies={suggestionsCompanies}
        availableCompanies={availableInsuranceCompanies ?? []}
        selectedGroup={selectedInsuranceGroup}
        selectedCompany={selectedInsuranceCompany}
        insuranceFormData={insuranceFormData}
        updateInsuranceFormData={updateInsuranceFormData}
        askForAddress={true}
        addressFormData={addressFormData}
        updateAddressFormData={updateAddressFormData}
        onSearchInputGroup={() => null}
        onSearchInputCompany={() => null}
        onSelectGroup={handleSelectInsurance}
        onSelectCompany={handleSelectInsuranceCompany}
        allInsuranceGroups={allInsuranceGroups}
        forceCompanySelection={force_insurance_company_selection}
      />
    </BookingPageContainer>
  );
};

export default InsuranceSelectionData;
