import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TInsuranceCompany, TInsuranceCompanyGroup } from '../../../pages/Booking/InsuranceSelection/type';

import { fetchInsuranceCompaniesByGroupApi, fetchInsuranceGroupsApi } from './api/insuranceApi';

type TInsuranceSlice = {
  insuranceGroups: TInsuranceCompanyGroup[] | null;
  availableInsuranceCompanies: TInsuranceCompany[] | null;
  selectedInsuranceGroup: TInsuranceCompanyGroup | null;
  selectedInsuranceCompany: TInsuranceCompany | null;
  isFetching: boolean;
  isFetched: boolean;
  error: Error | null;
};

export const fetchInsuranceGroups = createAsyncThunk('insurances/fetchInsuranceGroups', async () => {
  return await fetchInsuranceGroupsApi();
});

export const fetchInsuranceCompaniesByGroup = createAsyncThunk(
  'insurances/fetchInsuranceCompaniesByGroup',
  async (id: string) => fetchInsuranceCompaniesByGroupApi(id)
);

const initialState = {
  insuranceGroups: null,
  selectedInsuranceGroup: null,
  selectedInsuranceCompany: null,
  availableInsuranceCompanies: null,
  isFetching: false,
  isFetched: false,
  error: null,
} as TInsuranceSlice;

const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {
    setSelectedInsuranceGroup: (state, action) => {
      state.selectedInsuranceGroup = action.payload;
      state.selectedInsuranceCompany = null;
      state.availableInsuranceCompanies = null;
    },
    setSelectedInsuranceCompany: (state, action) => {
      state.selectedInsuranceCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInsuranceGroups.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
    });
    builder.addCase(fetchInsuranceGroups.fulfilled, (state, action) => {
      state.error = null;
      state.isFetched = true;
      state.isFetching = false;
      state.insuranceGroups = action.payload;
    });
    builder.addCase(fetchInsuranceGroups.rejected, (state, action) => {
      state.error = action.error as Error;
      state.isFetched = true;
      state.isFetching = false;
    });
    builder.addCase(fetchInsuranceCompaniesByGroup.pending, (state) => {
      state.error = null;
      state.isFetching = true;
      state.isFetched = false;
      state.availableInsuranceCompanies = null;
      state.selectedInsuranceCompany = null;
    });
    builder.addCase(fetchInsuranceCompaniesByGroup.fulfilled, (state, action) => {
      state.error = null;
      state.isFetched = true;
      state.isFetching = false;
      state.availableInsuranceCompanies = action.payload;
      state.selectedInsuranceCompany = null;
    });
    builder.addCase(fetchInsuranceCompaniesByGroup.rejected, (state, action) => {
      state.error = action.error as Error;
      state.isFetched = true;
      state.isFetching = false;
    });
  },
});

export const insuranceSliceActions = insurancesSlice.actions;
export default insurancesSlice.reducer;
