import { useIntl } from 'react-intl';

export const useInsuranceFormTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    insurantNumberLabel: formatMessage({
      id: 'Components.InsuranceForm.insurantNumber.label',
      description: 'label for insurant number input',
      defaultMessage: 'Versichertennummer',
    }),
    insurantNumberError: formatMessage({
      id: 'Components.InsuranceForm.insurantNumber.error',
      description: 'error message for insurant number input',
      defaultMessage:
        'Die Versicherungsnummer ist ungültig. \nEine Versicherungsnummer beginnt mit einem Buchstaben. Es folgen 9 Ziffern.',
    }),
    insuranceIdentificationNumberLabel: formatMessage({
      id: 'Components.InsuranceForm.insuranceIdentificationNumberLabel',
      description: 'label for insurance identification number input',
      defaultMessage: 'Kennnummer Ihrer Versicherung (auf der Karte links unten)',
    }),
    insuranceIdentificationNumberError: formatMessage({
      id: 'Components.InsuranceForm.insuranceIdentificationNumberError',
      description: 'error message for insurance identification number input',
      defaultMessage: 'Die Kennnummer muss ausgewählt werden und 9 Ziffern haben.',
    }),
    retiredOrFamilyInsuredLabel: formatMessage({
      id: 'Components.InsuranceForm.retiredOrFamilyInsuredLabel',
      description: 'label for retired or family insured select',
      defaultMessage: 'Sind Sie in Rente oder familienversichert?',
    }),
    retiredOrFamilyInsuredOptionNo: formatMessage({
      id: 'Components.InsuranceForm.retiredOrFamilyInsuredOptionNo',
      description: 'option - no - for retired or family insured select',
      defaultMessage: 'Nein',
    }),
    retiredOrFamilyInsuredOptionFamilyInsured: formatMessage({
      id: 'Components.InsuranceForm.retiredOrFamilyInsuredOptionFamilyInsured',
      description: 'option - family insured - for retired or family insured select',
      defaultMessage: 'Familienversichert',
    }),
    retiredOrFamilyInsuredOptionRetired: formatMessage({
      id: 'Components.InsuranceForm.retiredOrFamilyInsuredOptionRetired',
      description: 'option - retired - for retired or family insured select',
      defaultMessage: 'Rentner:in',
    }),
  };
};
