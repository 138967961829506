import { useIntl } from 'react-intl';

import { EInsuranceSelectionError } from './type';

export const useInsuranceSelectionTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    documentTitle: formatMessage({
      id: 'DocumentTitle.InsuranceSelection',
      description: 'Document title on insurance selection page',
      defaultMessage: 'avi - Versicherung',
    }),

    title: formatMessage({
      id: 'Pages.InsuranceSelection.title',
      description: 'Title for Insurance Selection page',
      defaultMessage: 'Wir benötigen nur noch Ihre Versicherungsdaten.',
    }),
    placeholder: formatMessage({
      id: 'Pages.InsuranceSelection.placeholder',
      description: 'Placeholder for Insurance Selection page',
      defaultMessage: 'Versicherung suchen',
    }),

    description: formatMessage({
      id: 'Pages.InsuranceSelection.description',
      description: 'Description for Insurance Selection page',
      defaultMessage: 'Bitte nennen Sie uns Ihre gesetzliche (GKV) oder private (PKV) Versicherung.',
    }),
    noResultTitle: formatMessage(
      {
        id: 'Pages.InsuranceSelection.noResultTitle',
        description: 'Description for no search result Insurance',
        defaultMessage:
          'Ihre Versicherung befindet sich nicht in unserer Liste. {br}Sind sie über diese Versicherung gesetzlich oder privat versichert?',
      },
      {
        br: <br />,
      }
    ),
    publicInsuranceLabel: formatMessage({
      id: 'Pages.InsuranceSelection.publicInsuranceLabel',
      description: 'Label for public insurance',
      defaultMessage: 'Gesetzlich (GKV)',
    }),
    privateInsuranceLabel: formatMessage({
      id: 'Pages.InsuranceSelection.privateInsuranceLabel',
      description: 'Label for private insurance',
      defaultMessage: 'Privat (PKV)',
    }),
    [EInsuranceSelectionError.GROUP_ERROR]: formatMessage({
      id: 'Pages.InsuranceSelection.insuranceErrorDescription',
      description: 'Description for insurance loading error',
      defaultMessage:
        'Leider kann die Liste der Versicherungen nicht angezeigt werden. Wir arbeiten an einer Lösung. Bitte laden Sie die Seite erneut oder gehen Sie einen Schritt zurück.',
    }),
    [EInsuranceSelectionError.COMPANY_ERROR]: formatMessage({
      id: 'Pages.InsuranceSelection.companiesErrorDescription',
      description: 'Description for insurance loading error',
      defaultMessage:
        'Leider kann die Liste der Versicherungsunternehmen nicht angezeigt werden. Wir arbeiten an einer Lösung. Bitte laden Sie die Seite erneut oder gehen Sie einen Schritt zurück.',
    }),
    [EInsuranceSelectionError.VALIDATION_ERROR]: formatMessage({
      id: 'Pages.InsuranceSelection.validationErrorDescription',
      description: 'Description for postal code validation error',
      defaultMessage:
        'Leider konnten wir nicht überprüfen, ob sich eine Praxis in der Nähe Ihres Wohnortes befindet. Wir arbeiten an einer Lösung. Bitte laden Sie die Seite erneut oder gehen Sie einen Schritt zurück.',
    }),
  };
};
